@use 'sass:math';
@import '../../styles/variables';

.amazonas-Modal {
  border: 1px solid var(--base25);
  width: 90%;
}

.ptr-Modal-closeButton .line {
  stroke-width: 2;
  stroke: var(--base90);
}

.amazonas-DeforestationProgressTitle {
  margin: .75rem 1rem;
}

// Content
.amazonas-DeforestationProgress {
  color: var(--base90);
  width: 100%;
  overflow: hidden;
  padding: 0 1rem 1rem;
}

.amazonas-DeforestationProgress h4 {
  margin: 0 0 .5rem;
}

.amazonas-DeforestationProgress-chart {
  height: 15rem;
  margin-bottom: m(1);
}

.amazonas-DeforestationProgress-animation > img {
  margin: 0;
}