@import '~@gisatcz/ptr-core/lib/styles/_variables';
@import '~@gisatcz/ptr-maps';
@import '~@gisatcz/ptr-components';
@import '~@gisatcz/ptr-atoms';
@import '~@gisatcz/ptr-core';
@import '~@gisatcz/ptr-timeline';
@import 'variables';

.ptr-light {
	--accent0: #{$lightAccent0};
	--accent05: #{$lightAccent05};
	--accent10: #{$lightAccent10};
	--accent15: #{$lightAccent15};
	--accent20: #{$lightAccent20};
	--accent25: #{$lightAccent25};
	--accent30: #{$lightAccent30};
	--accent35: #{$lightAccent35};
	--accent40: #{$lightAccent40};
	--accent45: #{$lightAccent45};
	--accent50: #{$lightAccent50};
	--accent50rgb: #{hexToRGB($lightAccent50)};
	--accent55: #{$lightAccent55};
	--accent60: #{$lightAccent60};
	--accent65: #{$lightAccent65};
	--accent70: #{$lightAccent70};
	--accent75: #{$lightAccent75};
	--accent80: #{$lightAccent80};
	--accent85: #{$lightAccent85};
	--accent90: #{$lightAccent90};
	--accent95: #{$lightAccent95};
	--accent100: #{$lightAccent100};
}

.ptr-dark {
	--accent0: #{$darkAccent0};
	--accent05: #{$darkAccent05};
	--accent10: #{$darkAccent10};
	--accent15: #{$darkAccent15};
	--accent20: #{$darkAccent20};
	--accent25: #{$darkAccent25};
	--accent30: #{$darkAccent30};
	--accent35: #{$darkAccent35};
	--accent40: #{$darkAccent40};
	--accent45: #{$darkAccent45};
	--accent50: #{$darkAccent50};
	--accent50rgb: #{hexToRGB($darkAccent50)};
	--accent55: #{$darkAccent55};
	--accent60: #{$darkAccent60};
	--accent65: #{$darkAccent65};
	--accent70: #{$darkAccent70};
	--accent75: #{$darkAccent75};
	--accent80: #{$darkAccent80};
	--accent85: #{$darkAccent85};
	--accent90: #{$darkAccent90};
	--accent95: #{$darkAccent95};
	--accent100: #{$darkAccent100};
}

/**
 * until https://github.com/facebook/create-react-app/issues/11771
 * gets fixed, let's hide the problem and not address it
 */
body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
	display: none;
}

// custom scrollbar
/* width */
::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--base40);
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--base50);
}
