@use 'sass:math';
@import '../../../styles/variables';

.amazonas-IntroHeader {
  position: fixed;
  height: 3.5rem;
  display: flex;
  align-items: center;
  padding-top: .15rem;
  border-bottom: 1px solid var(--base15);
  background: rgb(0, 0, 0);
  width: calc(100% - 5px);
  z-index: 2;
}

.amazonas-IntroHeader-logo {
  width: 8rem;
  margin-top: .3rem;

  @include maxWidth('small') {
    width: 6rem;
  }
}

.amazonas-IntroHeader-divider {
  color: var(--base50);
  margin-right: 1.5rem;
  font-size: $b2;

  @include maxWidth('small') {
    margin-right: 1rem;
  }
}

.amazonas-IntroHeader-title {
  color: var(--base95);
  font-size: $b2;
  font-weight: 600;
  cursor: pointer;

  @include maxWidth('small') {
    font-size: $a1;
  }
}