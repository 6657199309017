@use 'sass:math';
@import '../../../../styles/variables';

// Attribute scale
.amazonas-MapLegend-AttributeScale {
  display: flex;
  flex: 1;
}

.amazonas-MapLegend-AttributeScale.is-expanded {
  width: 100%;
}

.amazonas-MapLegend-AttributeScale > span {
  font-size: $b1;
  line-height: 1;
  color: var(--base50);
}

.amazonas-MapLegend-AttributeScale > div {
  flex: 1;
  height: .75rem;
  margin: 0 .25rem;
}

