@use 'sass:math';
@import '../../../styles/variables';

.amazonas-Hero {
  height: 100%;
  padding: m(1) m(3) m(3);
  display: flex;
  align-items: center;
  grid-gap: m(4);
  background: var(--base05);

  @include minWidth('xx-large') {
    padding: m(1) m(3) m(5);
    grid-gap: m(5);
  }

  @include maxWidth('medium') {
    padding: m(1) m(2) m(3);
    grid-gap: m(2);
  }

  @include maxWidth('small') {
    flex-direction: column-reverse;
    padding: m(3) m(1) m(2);
    grid-gap: m(1);
    align-items: flex-start;
  }
}

.amazonas-Hero-logo {
  max-width: 20rem;

  @include maxWidth('medium') {
    max-width: 15rem;
  }

  @include maxWidth('small') {
    max-width: 12rem;
  }

  @include maxWidth('x-small') {
    max-width: 10rem;
  }
}

.amazonas-Hero-text {
  @include maxWidth('small') {
    flex: 1;
  }
}

.amazonas-Hero-text h1 {
  font-size: $b4;

  @include minWidth('xx-large') {
    font-size: $a4;
  }

  @include maxWidth('medium') {
    font-size: $a3;
  }

  @include maxWidth('small') {
    font-size: $b3;
  }

  @include maxWidth('x-small') {
    font-size: $a2;
    margin-bottom: 1rem;
  }
}

.amazonas-Hero-text p {
  color: var(--base80);
  font-size: $a1;

  @include minWidth('xx-large') {
    font-size: $b2;
  }
}

.amazonas-Hero-buttons {
  margin-top: m(1);
  display: flex;
  grid-gap: 1rem;

  @include minWidth('xx-large') {
    margin-top: 2rem;
  }
}