@use 'sass:math';
@import '../../styles/variables';

.amazonas-App {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  background-color: var(--base05);
}