@use 'sass:math';
@import '../../../styles/variables';

.amazonas-About {

}

.amazonas-About-section {
  padding: m(4) m(3);
  position: relative;
  background: var(--base05);

  @include maxWidth('medium') {
    padding: m(3) m(2);
  }

  @include maxWidth('small') {
    padding: m(2) m(1);
  }
}

.amazonas-About-section > h2 {
  margin-top: 0;
}

.amazonas-About-section div,
.amazonas-About-section p {
  color: var(--base80);
}


.amazonas-About .amazonas-About-section:nth-child(odd) {
  background: var(--base15);
}

.amazonas-About-footer div,
.amazonas-About-footer p {
  font-size: $a1;
  color: var(--base80);
}

/* Schema */
.amazonas-About-schema {
  margin-bottom: m(2);
  width: 100%;
  max-width: 50rem;
}

/* Partner cards */
.amazonas-partners {
  display: flex;
  flex-wrap: wrap;
  max-width: 50rem;
  justify-content: flex-start;
  margin: m(1) 0 0;
  padding: m(1) 0;
  grid-gap: m(1);
}

.amazonas-partner-card {
  flex: 1;
  padding: m(5/3) m(1);
  max-width: 23rem;
  min-width: 18rem;
  background: var(--base05);
  box-shadow: 0 10px 20px rgba(var(--base0rgb), .25);
  transition: box-shadow .2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}

.amazonas-partner-card:hover {
  box-shadow: 0 10px 20px rgba(var(--base0rgb), .7);
  background: var(--base0);
}

.amazonas-partner-card > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.amazonas-partner-card-img-wrapper {
  height: 6rem;
  display: flex;
  align-items: center;
  margin-bottom: m(1);
}

.amazonas-partner-card img {
  max-width: 10rem;
}

.amazonas-partner-card img.smaller {
  max-width: 8rem;
}

.amazonas-partner-card-title {
  font-size: $b2;
  font-weight: bold;
  text-align: center;
  color: var(--base100);
}

.amazonas-partner-card-subtitle {
  color: var(--base60);
  margin-bottom: m(2/3);
}

.amazonas-partner-card-person {
  color: var(--base100);
}

/* Resources */
.amazonas-About-list {
  margin-left: 1rem;
}

.amazonas-About-list li {
  line-height: 2rem;
}

.amazonas-About-list li span {
  line-height: 1rem;
  font-size: $b1;
  margin-left: .25rem;
  color: var(--base50);
}

.amazonas-About-list .ptr-icon,
.amazonas-About-list .ptr-react-icon {
  margin: 0 .35rem -.25rem 0;
}

.amazonas-About-list .ptr-icon .line {
  fill: var(--accent50)
}