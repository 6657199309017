@use 'sass:math';
@import '../../../styles/variables';

.amazonas-MapWrapper {
  position: relative;
}

.ptr-map-wrapper.amazonas-MapWrapper.one-map.active:after {
  border: 0;
}

.ptr-map-wrapper.amazonas-MapWrapper.active::after {
  border: 2px solid var(--accent50);
  z-index: 1;
}

.amazonas-LayerLabelsContainer {
  position: relative;
  z-index: 2;
}

.amazonas-LayerLabelsContainer > div {
  position: relative;
}

.amazonas-MapTools {
  position: absolute;
  top: .5rem;
  right: .5rem;
  z-index: 2;
  display: flex;
  align-items: flex-start;
  grid-gap: .05rem;
  border-radius: .25rem;
}

.amazonas-MapTools.is-left {
  left: 3.25rem;
  right: auto;
}

.ptr-map-wrapper .amazonas-MapToolsButton.ptr-button {
  background: var(--base05);
}

.ptr-map-wrapper.amazonas-MapWrapper.active .amazonas-MapToolsButton.ptr-button {
  background: var(--accent50);
}

.amazonas-MapToolsButton.ptr-button .line {
  fill: var(--base90);
  stroke: var(--base90);
  stroke-width: 2;
  transition: stroke .3s ease-in-out;
}

.ptr-map-wrapper.amazonas-MapWrapper.active .amazonas-MapToolsButton.ptr-button .line {
  fill: var(--base10);
  stroke: var(--base10);
  stroke-width: 2;
}