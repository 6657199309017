@use 'sass:math';
@import '../../../../styles/variables';

.amazonas-LayerControl {
  padding: 0 m(1);
}

.ptr-Checkbox{
  cursor: pointer;
  display: flex;
  align-items: center;

  >span {
    margin-left: 0.35rem;
    color: var(--base90);
  }
}