@use 'sass:math';
@import '../../styles/variables';

.amazonas-Timeline {
  position: relative;
  background: var(--base05);
  color: var(--base50);
  min-height: 4rem;
  max-width: 100%;
  z-index: 1000;
  border-top: 1px solid var(--base0);
  padding-bottom: .5rem;
}

.amazonas-Timeline .ptr-maptimelinelegend{
  width: 11rem;
}

.amazonas-Timeline .ptr-timeline-legend-placeholder{
  min-width: 11rem;
}

.amazonas-Timeline g.ptr-timeline-year, .ptr-timeline-year > line,
.amazonas-Timeline g.ptr-timeline-month, .ptr-timeline-month > line{
  fill: var(--base05)
}

.amazonas-Timeline .ptr-timeline-year-label,
.amazonas-Timeline .ptr-timeline-month-label{
  fill: var(--accent40);
}