@use 'sass:math';
@import '../../styles/variables';

$mapBorder: 0.05rem solid var(--base50); // the width in rem fixes the ugly white space between border and content in Chrome

.amazonas-Maps {
  flex: 1;
  background-image:  linear-gradient(var(--base20) 1px, transparent 1px), linear-gradient(to right, var(--base20) 1px, transparent 1px);
  background-size: 20px 20px;
  position: relative;
}

// Map set
.amazonas-Maps .ptr-map-set {
  position: relative;
  z-index: 2;
  margin-top: -1px;
}

.amazonas-Maps .ptr-map-grid .ptr-map-grid-cell .ptr-map {
  border: 0;
}

.amazonas-Maps .ptr-map-grid .ptr-map-grid-cell:not(.row1) .ptr-map {
  border-top: $mapBorder;
}

.amazonas-Maps .ptr-map-grid .ptr-map-grid-cell:not(.col1) .ptr-map {
  border-left: $mapBorder;
}

.amazonas-Maps .ptr-map.ptr-ReactLeafletMap {
  background: none;
}

.amazonas-Maps .ptr-map-controls-wrapper {
  z-index: auto;
}

// Map slider
.amazonas-CompareSlider {
  height: 100%;
  margin-top: -1px;
}

div[data-rcs="handle-container"] {
  z-index: 3;
}

div[data-rcs="clip-item"] {
  z-index: 2;
}

// MapInfoElements
.amazonas-MapInfoElements {
  pointer-events: none;
  position: absolute;
  bottom: .35rem;
  left: .35rem;
  display: flex;
  grid-gap: .35rem;
  flex-direction: column;
  z-index: 9;
}

// AttributionScaleContainer
.amazonas-AttributionScaleContainer {
  display: flex;
  grid-gap: .35rem;
  position: relative;
}
.amazonas-AttributionScaleContainer.is-shifted {
  left: 2.75rem;
}

// Map set controls
.amazonas-MapSetControls {
  position: absolute;
  right: .5rem;
  bottom: .5rem;
  grid-gap: .5rem;
  display: flex;
  flex-direction: column;
  z-index: 9;
}

.ptr-dark .ptr-ZoomControls .zoom-control.control {
  border: 0;
}

.ptr-dark .ptr-IconTool.is-floating {
  border: 0;
}