@use 'sass:math';
@import '../../styles/variables';

.amazonas-Intro {
  position: absolute;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 3.5rem;
  height: 3.5rem;
  background: rgba(0, 0, 0, 0);
  overflow: hidden;
  transition: width .5s ease-in-out, height .5s ease-in-out, background .5s ease-in-out, border-radius .5s ease-in-out;
  border-radius: 1rem;
}

.amazonas-Intro.is-open {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 1);
  border-radius: 0;
}

/* Control */
.amazonas-Intro .amazonas-Intro-control {
  position: absolute;
  right: 1rem;
  top: 0.75rem;
  color: var(--base80);
  z-index: 3;
}

.amazonas-Intro .amazonas-Intro-control .ptr-react-icon {
  transition: transform .5s ease-in-out;
}

.amazonas-Intro.is-open .amazonas-Intro-control .ptr-react-icon {
  transform: rotate(180deg);
}

/* Content */
.amazonas-Intro-content {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity .5s ease-in-out;
}

.amazonas-Intro.is-open .amazonas-Intro-content {
  opacity: 1;
  pointer-events: auto;
}

.amazonas-Intro-body {
  height: 100%;
  overflow-y: auto;
}