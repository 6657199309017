@import '../../../styles/variables';

.amazonas-VectorLayerLabel {
  background: var(--base0);
  min-width: 14rem;
}

// Header
.amazonas-VectorLayerLabelHeader {
  display: flex;
  font-size: $b1;
  border-right: 1px solid rgba(var(--base100rgb), .2);
  min-height: 1.8rem;
}

.amazonas-VectorLayerLabelHeader-color {
  width: 5px;
  background: yellow;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.ptr-ExpandableLabel.is-expanded .amazonas-VectorLayerLabelHeader-color {
  border-bottom-left-radius: 0;
}

.amazonas-VectorLayerLabelHeader-body {
  padding: .25rem .75rem .25rem .5rem;
}

.amazonas-VectorLayerLabelHeader-title {
  display: flex;
  align-items: center;
  color: var(--base90);
  font-weight: bold;
}

.amazonas-VectorLayerLabel .ptr-ExpandableLabelHeader-controlButton {
  min-height: 1.8rem;
}

.amazonas-VectorLayerLabelHeader-period {
  font-size: $b1;
  color: var(--base60);
  line-height: 1rem;
}

// Content
.amazonas-VectorLayerLabelBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--base0);
}

.amazonas-VectorLayerLabelBodyItem {
  display: flex;
  height: 2rem;
  font-size: $b1;
  align-items: center;
  padding: 0 .5rem;
  color: var(--base80);
  border-top: 1px solid var(--base30);
  text-decoration: none;
}

.amazonas-VectorLayerLabelBodyItem:last-of-type {
  //border-bottom: 1px solid var(--base20);
}

.amazonas-VectorLayerLabelBodyItem.is-hoverable {
  cursor: pointer;
}

.amazonas-VectorLayerLabelBodyItem.is-hoverable:hover,
a.amazonas-VectorLayerLabelBodyItem.is-hoverable:hover {
  background: var(--base20);
  color: var(--base90);
}

.amazonas-VectorLayerLabelBodyItem.is-dangerous {
  color: #c12123;
}

.amazonas-VectorLayerLabelBodyItem.is-dangerous:hover {
  background: #f1d7d8;
}

.amazonas-VectorLayerLabelBodyItem.is-disabled {
  color: var(--base30);
  pointer-events: none;
}

.amazonas-VectorLayerLabelBodyItem-title {
  flex: 1;
}

.amazonas-VectorLayerLabelBodyItem-tool {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.amazonas-VectorLayerLabelBodyItem .ptr-icon {
  width: 1rem;
  height: 100%;
}

.amazonas-VectorLayerLabelBodyItem .ptr-icon .line {
  fill: var(--base70);
  stroke: var(--base70);
}

.amazonas-VectorLayerLabelBodyItem.is-dangerous .ptr-icon .line {
  fill: #c12123;
}

.amazonas-VectorLayerLabelBodyItem.is-disabled .ptr-icon .line {
  fill: var(--base30);
  stroke: var(--base30);
}